import React, {useEffect} from 'react';
import Splash from '../components/featured/Splash';
import { graphql, StaticQuery } from 'gatsby';
import Cookies from 'js-cookie';
import Seo from '../components/Seo';
import Header from '../components/Header';
import Layout from '../components/Layout';
import Images from '../components/featured/Images';
import '../styles/components/homepage-images.scss';

// let supportsTouch;

class Featured extends React.Component {

	constructor( props ) {
		super( props );
		this.state = {
			landing: Cookies.get( 'landing-viewed' ),
		}
	}

	componentDidMount() {
		// supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;	
		// console.log(supportsTouch);
	}

	render() {

		return (
			<>
			<StaticQuery 
				query={graphql`query featured {
				  wpPage(id: {eq: "cG9zdDo3"}) {
				    id
				    title
				    uri
				    seo {
				      metaDesc
				      metaKeywords
				      metaRobotsNoindex
				      focuskw
				      opengraphAuthor
				      opengraphDescription
				      opengraphImage {
				        localFile {
				          childImageSharp {
				            fixed {
				              src
				            }
				          }
				        }
				      }
				      opengraphModifiedTime
				      opengraphPublishedTime
				      opengraphPublisher
				      opengraphSiteName
				      opengraphTitle
				      opengraphType
				      opengraphUrl
				      title
				      twitterDescription
				      twitterImage {
				        localFile {
				          childImageSharp {
				            fixed {
				              src
				            }
				          }
				        }
				      }
				      twitterTitle
				    }
				    homepageImages {
				      homepageImages {
				        ... on WpPage_Homepageimages_HomepageImages_OneImage {
				          caption
				          fieldGroupName
				          image {
				            localFile {
				              extension
				              publicURL
				              childImageSharp {
				                fluid( maxWidth: 2200 ) {
				                	...GatsbyImageSharpFluid_noBase64
				                }
				              }
				            }
				          }
				          type
				          video {
				            localFile {
				            	publicURL
				            }
				          }
				        }
				        ... on WpPage_Homepageimages_HomepageImages_TwoImages {
				          caption
				          fieldGroupName
				          imageOne {
				            localFile {
				              extension
				              publicURL
				              childImageSharp {
				                fluid( maxWidth: 2200 ) {
				                	...GatsbyImageSharpFluid_noBase64
				                }
				              }
				            }
				          }
				          imageTwo {
				            localFile {
				              extension
				              publicURL
				              childImageSharp {
				                fluid( maxWidth: 2200 ) {
				                	...GatsbyImageSharpFluid_noBase64
				                }
				              }
				            }
				          }
				          typeOne
				          typeTwo
				          videoOne {
				            localFile {
				            	publicURL
				            }
				          }
				          videoTwo {
				            localFile {
				            	publicURL
				            }
				          }
				        }
				        ... on WpPage_Homepageimages_HomepageImages_ThreeImages {
				          caption
				          fieldGroupName
				          imageThree {
				            localFile {
				              extension
				              publicURL
				              childImageSharp {
				                fluid( maxWidth: 2200 ) {
				                	...GatsbyImageSharpFluid_noBase64
				                }
				              }
				            }
				          }
				          imageTwo {
				            localFile {
				              extension
				              publicURL
				              childImageSharp {
				                fluid( maxWidth: 2200 ) {
				                	...GatsbyImageSharpFluid_noBase64
				                }
				              }
				            }
				          }
				          typeOne
				          typeThree
				          typeTwo
				          videoThree {
				            localFile {
				            	publicURL
				            }
				          }
				          videoTwo {
				            localFile {
				            	publicURL
				            }
				          }
				          videoOne {
				            localFile {
				            	publicURL
				            }
				          }
				          imageOne {
				            localFile {
				            	extension
				            	publicURL
				              childImageSharp {
				                fluid( maxWidth: 2200 ) {
				                	...GatsbyImageSharpFluid_noBase64
				                }
				              }
				            }
				          }
				        }
				      }
				    }
				  }
				  wp {
				    generalSettings {
				      title
				      description
				    }
				    siteSettings {
				      landingPageText {
				        landingPageText
				      }
				    }
				  }
				}`
				}
				render={ ( featured ) => (
					<>
					<Seo 
				    	bodyClasses="bg:white nav-hidden" 
				    	siteTitle={featured.wp.generalSettings.title}
						siteDescription={featured.wp.generalSettings.description}
						title={featured.wpPage.seo.title}
						metaDesc={featured.wpPage.seo.metaDesc}
						focusKeywords={featured.wpPage.seo.focuskw}
						opengraphTitle={featured.wpPage.seo.opengraphTitle}
						opengraphDescription={featured.wpPage.seo.opengraphDescription}
						opengraphImage={featured.wpPage.seo.opengraphImage}
						opengraphImage={featured.wpPage.seo.opengraphImage}
						twitterDescription={featured.wpPage.seo.twitterDescription}
						twitterImage={featured.wpPage.seo.twitterImage}
						twitterTitle={featured.wpPage.seo.twitterTitle}
				    />
					<Layout>
						<Header 
							title={ featured.wpPage.title } 
							url={ featured.wpPage.uri } 
							id={ featured.wpPage.id }
						/>
						<Images media={ featured.wpPage.homepageImages } />
						<Splash text={ featured.wp.siteSettings.landingPageText.landingPageText } />
					</Layout>
					</>
				)}
				/>	
			</>
		);

	}

}

export default Featured;