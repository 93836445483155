import React, { useComponent, useState, useEffect, useSession } from 'react';
import '../../styles/components/splash.scss';
import '../../styles/components/loader.scss';

class Splash extends React.Component {

	constructor( props ) {
		super(props);

		this.state = {
			visible: true
		}

	}

	componentDidMount() {

		setTimeout(() => {
	
			this.setState({
				visible: false
			});
			

		}, 4500);
		
		document.cookie = `landing-viewed=true;`

	}

	render() {

		return (
			<div className={ 'splash' }>
				<div className={ 'loader' }>
					<div className={ 'loader-bar' }>
					</div>
				</div>
				<div dangerouslySetInnerHTML={{ __html: this.props.text }}/>
			</div>
		);

	}

}

export default Splash;